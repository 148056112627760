<template>
  <div class="fxlogin">
    <div class="byp"></div>
    <div class="fx-topcolor-wrap">
      <div class="fx-topcolor"></div>
    </div>
    <div class="fxwrap">
      <div class="fxcontent">
        <img class="fxmain-logo" :src="config.SITE_LOGO_URL" alt="logo" />
        <div class="fxm-div"></div>
        <div class="fspacer"></div>
        <div class="fxtitle">
          <div class="fxtl fxtl1" @click="loginLabelClicked()">Hello.</div>
          <div class="fxtl fxt2">Welcome Back.</div>
        </div>
        <form name="login">
          <div class="fspacer"></div>
          <div class="fxlabel">Email</div>
          <k-text-input
            ref="email"
            :required="false"
            :autofocus="true"
            @change="onChangeEmail"
            @keyup="onKeyUp"
          />
          <div class="fspacer"></div>
          <div class="fxlabel">Password</div>
          <k-text-input
            ref="password"
            :required="false"
            :default-focus="true"
            type="password"
            @change="onChangePassword"
            @keyup="onKeyUp"
          />
          <k-link
            class="fx-forgot"
            :to="{ path: '/forgot_password?app=' + appId + '&mode=' + mode }"
          >
            Forgot Password
          </k-link>
          <div style="height:20px;"></div>
          <div class="fspacer"></div>
          <input type="button" value="Sign in" class="fxmvc-btn" @click="onClickLogin" />
          <div class="fspacer"></div>
          <div v-if="config.GOOGLE_SIGN_IN" id="orBeforeGoogleSignin" class="fx-or">
            <span class="fx-or-txt">or</span>
          </div>
          <GoogleSignInButton
            v-if="config.GOOGLE_SIGN_IN"
            id="googleSignInButton"
            :o-auth-url-host="`${config.ADREADY_URL}`"
            class="google-signin row center-xs middle-xs plain"
            @click="onGoogleSignIn"
          />
          <!-- <div v-if="isFlipPlus()" class="btm_signup_wrap">
            Don't have an account?&nbsp;
            <k-link :to="{ path: '/signup?app=' + appId + '&mode=' + mode }">Sign Up</k-link>
          </div> -->
        </form>
        <div v-if="error" class="error">Login Error: {{ error }}</div>
      </div>
    </div>
    <video autoplay muted loop playsinline style="">
      <source src="https://demo.digitalremedy.com/platform/chart2-bw.mp4" type="video/mp4" />
    </video>
    <div class="bgtint bgtint-grad"></div>
    <div class="bgtint bgtint1"></div>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies';
import { COOKIE_NAME_REDIRECT_URI } from 'adready-api/helpers/cookie-session-helper';
import { isBlank } from 'adready-api/helpers/common';
import { isEmailValid } from '@/util/utility-functions';
import axios from 'axios';
import config from '~/config';
import GoogleSignInButton from '~/adready-vue/components/elements/google-sign-in-button.vue';
import { APP_CONSOLE_ID, EMAIL_VALIDATION_MSG } from '~/constant';

export default {
  name: 'AppConsoleLogin',

  components: {
    GoogleSignInButton,
    KLink: () =>
      import(/* webpackChunkName: "k-link" */ '../../adready-vue/components/elements/k-link.vue'),
  },

  props: {
    err: {
      required: false,
      type: String,
      default: undefined,
    },
    mode: {
      required: false,
      type: String,
      default: '',
    },
  },

  data() {
    return {
      login: {},
      appId: null,
      errorMsg: null,
      config,
      shiftPressed: false,
    };
  },
  computed: {
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      if (!isBlank(this.err)) {
        return this.err;
      }
      return null;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.checkKeyDown);
    window.addEventListener('keyup', this.checkKeyUp);
  },

  created() {
    this.appId = this.$route.query.app || APP_CONSOLE_ID;
    localStorage?.setItem('mode', this.mode);
    window.document.title = 'Login';
  },
  methods: {
    checkKeyDown(event) {
      if (event.key === 'Shift') {
        this.shiftPressed = true;
      }
    },
    checkKeyUp(event) {
      if (event.key === 'Shift') {
        this.shiftPressed = false;
      }
    },
    loginLabelClicked() {
      if (this.shiftPressed) {
        this.$set(this.config, 'GOOGLE_SIGN_IN', '1');
      }
      // if (this.shiftPressed) {
      //   console.log('Im in');
      //   const orBeforeGSIn = document.getElementById('orBeforeGoogleSignin');
      //   orBeforeGSIn.style = 'display:block !important';
      //   const googleSInButton = document.getElementById('googleSignInButton');
      //   googleSInButton.style = 'display:block !important';
      // }
    },
    onChangeEmail(val) {
      // update text directly
      this.login.email = val;
    },

    onChangePassword(val) {
      // update text directly
      this.login.password = val;
    },
    onKeyUp(val, e) {
      if (e.key && e.key.includes('Enter')) {
        this.onClickLogin();
      }
    },
    async onClickLogin() {
      // remove the 'redirect_uri' cookie which has been setup on full domain url.
      VueCookies.remove(COOKIE_NAME_REDIRECT_URI);
      if (isBlank(this.login.email)) {
        this.errorMsg = 'Email/password not set';
        this.$refs.email.focus();
        return;
      }
      if (isBlank(this.login.password)) {
        this.errorMsg = 'Email/password not set';
        this.$refs.password.focus();
        return;
      }
      if (!isEmailValid(this.login.email)) {
        this.errorMsg = EMAIL_VALIDATION_MSG;
        this.$refs.email.focus();
        return;
      }
      this.errorMsg = null;
      try {
        const success = await axios.post(
          `${config.ADREADY_URL}/api/auth/login?app=${this.appId}`,
          this.login,
          {
            withCredentials: true,
          }
        );
        if (success) {
          window.location = `${config.APP_URL}`;
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          this.errorMsg = 'Bad Request, Please contact support ';
        } else if (error?.response?.status === 401) {
          const msg = error.response.data.message;
          if (isBlank(msg)) {
            this.errorMsg = 'You are not authorised to access the application';
          } else {
            this.errorMsg = msg;
            if (msg.includes('disabled')) {
              this.errorMsg += '. Please contact support.';
            } else if (msg.includes('verified')) {
              const userId = error.response.data.result;
              this.verifyEmailPayload = { show: true, email: this.login.email, userId };
              this.errorMsg += '. Please verify your email address.';
            }
          }
        } else {
          this.errorMsg = 'error processing login';
          // eslint-disable-next-line no-console
          console.error(this.errorMsg, error);
          if (this.$sentry) {
            if (error._reported !== true) {
              this.$sentry.captureException(error);
              error._reported = true;
            }
          }
        }
      }
    },
    onGoogleSignIn() {
      VueCookies.set(COOKIE_NAME_REDIRECT_URI, config.APP_URL, null, null, config.COOKIE_DOMAIN);
    },
  },
};
</script>
<style lang="scss">
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800';
</style>
<style lang="scss" scoped>
.fxlogin {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.fxmain-logo {
  width: auto;
  height: 70px;
  margin: 0px;
  margin-bottom: 40px;
  transition: all 0.3s ease;
}
.fxcontent {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
  max-width: 490px;
}

.fxloginimg {
  width: 420px;
  height: 373px;
}
.fxwrap {
  width: 600px;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  max-width: 37%;
  width: 100%;
  min-width: 400px;
  padding-bottom: 4vh;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.95) 40%,
    rgba(244, 245, 250, 0.97) 100%
  );
  box-shadow: 50px 0px 80px -50px rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: saturate(280%) blur(10px);
  backdrop-filter: saturate(20%) blur(2px);
}
input[type='button'] {
  width: 100%;
}
.fxmvc-btn {
  background-color: var(--primarycolor);
  font-size: 13px;
  font-family: arial, 'Manrope', sans-serif;
  padding: 18px 18px;
  border: 0px;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -moz-appearance: none;
  appearance: none;
  color: var(--buttontext);
  text-transform: uppercase;
  letter-spacing: 0.07em;
  display: inline-block;
  font-weight: 400;
  border-radius: 9px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-align: center;
  min-width: 170px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0px 10px 10px -10px rgba(0, 70, 90, 0.3);
}

.fxmvc-btn:hover {
  letter-spacing: 0.15em;
  filter: brightness(1.1);
  box-shadow: 0px 20px 20px -20px rgba(0, 70, 90, 0.5);
}
.fxlabel {
  font-family: 'Manrope', sans-serif;
  font-size: 1em;
  color: var(--primarydark2);
  margin: 20px 0 4px 0;
  font-weight: 500;
  letter-spacing: 0.03em;
}

::v-deep(.form-input-field) {
  box-sizing: border-box;
  width: 100%;
  padding: 17px 20px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #c5d2d8;
  -webkit-appearance: none;
  border-radius: 9px;
}
::v-deep(.form-input-field:hover) {
  border: 1px solid #8fc2d9;
}
.fxinput {
  box-sizing: border-box;
  width: 100%;
  padding: 17px 20px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #c5d2d8;
  -webkit-appearance: none;
  border-radius: 9px;
}
.fxinput:hover {
  border: 1px solid #8fc2d9;
}
input:focus-visible {
  outline: 1px solid var(--primarycolor);
  border-radius: 9px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0.7) inset !important;
}

.fxtitle {
  font-family: 'Manrope', sans-serif;
  color: var(--primarydark);
  font-size: 2.7em;
  font-weight: 600;
  margin-bottom: 50px;
  line-height: 1em;
}
.byp {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 99;
}
.lg-bg-img {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
  display: none;
  width: 140vw;
  opacity: 0.6;
  transform: translate(-42%, -50%);
}
// .bgtint {
//   position: fixed;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
//   z-index: 2;
//   width: 100%;
//   height: 100vh;
//   background-color: var(--primarycolor);
//   background-image: radial-gradient(circle, rgba(0, 0, 0, 0.03) 1px, rgba(0, 0, 0, 0) 1px);
//   background-size: 5px 5px;
// }

// .bgtint-grad {
//   background-color: #d7d8d9;
//   background: var(--primarycolor);
//   background: linear-gradient(45deg, rgba(214, 214, 214, 1) 0%, rgba(117, 117, 117, 1) 100%);
//   background: linear-gradient(45deg, rgba(214, 214, 214, 1) 43%, rgba(117, 117, 117, 1) 100%);
//   background: linear-gradient(45deg, rgba(0, 0, 0, 0) 43%, rgba(0, 0, 0, 1) 100%);
//   z-index: 3;
//   opacity: 0.3;
// }
.google-signin {
  -webkit-box-sizing: border-box;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  box-sizing: border-box;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: 100%;
  height: 50px !important;
  padding: 0 8px;
  line-height: 50px !important;
  color: #42526e !important;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: default;
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0 !important;
  border-width: 0;
  border-radius: 9px;
  outline: medium none currentcolor !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0 !important;
  box-shadow: 1px 1px 8px 0 rgba(0, 70, 110, 0.2) !important;
  -webkit-transition: background 0.1s ease-out 0s,
    -webkit-box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  transition: background 0.1s ease-out 0s,
    -webkit-box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s,
    -webkit-box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
}
::v-deep .google-signin .signin {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
}
.google-signin:hover {
  background-color: rgba(255, 255, 255, 1) !important;
}
.google-logo {
  position: relative;
  margin-right: 15px;
  top: 4px;
}
.fx-or {
  font-family: 'Manrope', sans-serif;
  position: relative;
  margin: 17px 0px;
  color: var(--primarylight);
  text-align: center;
}
.fx-or::before {
  position: absolute;
  z-index: -1;
  width: 40px;
  height: 1px;
  margin-top: 11px;
  margin-left: -45px;
  content: '';
  background-color: rgba(0, 0, 0, 0.1);
}
.fx-or::after {
  position: absolute;
  z-index: -1;
  width: 40px;
  height: 1px;
  margin-top: 11px;
  margin-left: 4px;
  content: '';
  background-color: rgba(0, 0, 0, 0.1);
}
.fx-or-txt {
  padding: 0px 4px;
  line-height: 1em;
}
.fx-forgot {
  padding: 6px 1px 1px;
  text-align: right;
  font-family: 'Manrope', sans-serif;
  font-size: 0.86em;
  font-weight: 600;
  color: var(--primarycolor);
  text-align: right;
  cursor: pointer;
  &:hover {
    color: var(--primarydark2);
  }
}

.fx-topcolor,
.fx-topcolor-wrap,
.fxm-div,
.fxm-corner-img-wrap {
  display: none;
}
.fxtl1 {
  font-size: 1.1em;
  color: var(--primarycolor);
}
.fspacer {
  display: none;
  height: 300px;
  transition: all 0.7s ease;
}
.nospace {
  height: 1px;
}

.btm_signup_wrap {
  display: flex;
  margin-top: 20px;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  font-size: 15px;
  color: var(--primarydark2);
}

.btm_signup_wrap a {
  font-family: 'Manrope', sans-serif;
  font-size: 15px;
  color: var(--primarycolor);
  &:hover {
    color: var(--primarydark2);
  }
}

@media (max-height: 760px) {
  .fxmain-logo {
    margin-bottom: 30px;
  }
  .fxtitle {
    margin-bottom: 20px;
    font-size: 2.4em;
  }
}
@media (max-height: 690px) {
  .fxwrap {
    display: inline-block;
    overflow: scroll;
  }
}

@media (max-width: 600px) {
  .fxm-corner-img-wrap {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 8;
    display: inline-block;
    opacity: 0.6;
  }

  .fxm-div {
    position: absolute;
    display: block;
    width: calc(100% + 70px);
    height: 1px;
    margin-left: -35px;
    border-bottom: 1px solid #eee;
  }

  .fx-topcolor-wrap {
    position: absolute;
    z-index: 9;
    display: block;
    width: 100%;
    height: 95px;
    overflow: hidden;
  }

  .fx-topcolor {
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    width: 600px;
    height: 0;
    border-top: 18px solid var(--primarycolor);
    border-right: 600px solid var(--primarycolor);
    border-bottom: 20px solid transparent;
    opacity: 0.9;
  }

  .fxwrap {
    width: 100%;
    max-width: 100%;
  }
  .bgtint,
  .lg-bg-img {
    display: none !important;
  }

  .fxtitle {
    margin-top: 40px;
    margin-bottom: 23px;
    font-size: 2em;
    font-weight: 700;
    line-height: 1.1em;
  }
  .fxmain-logo {
    margin-bottom: 5px;
  }
  .fxlogin,
  .fxwrap {
    position: relative;
    min-width: 280px;
    overflow: hidden;
  }
  .fxcontent {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    padding: 52px 35px;
  }
} /*600 @media end*/

.lgn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  z-index: 4;
  opacity: 0.7;
  .scene {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    background: transparent;
  }
  .scene-layer {
    position: absolute;
  }
  .lbg {
    position: absolute;
  }

  .lbg-1 {
    top: -250px;
    right: -2050px;
    width: 700px;
  }

  .lbg-2 {
    top: 55vh;
    left: 27vw;
    width: 200px;
    opacity: 0.8;
    display: none;
  }

  .lbg-3 {
    top: -25vh;
    left: -15vw;
    width: 130vw;
    opacity: 0.6;
    filter: drop-shadow(42px 94px 10px rgba(0, 0, 0, 0.2));
  }

  .lbg-4 {
    top: 60vh;
    left: 70vw;
    width: 100px;
  }

  .lbg-5 {
    top: 18vh;
    left: 65vw;
    width: 250px;
    filter: drop-shadow(20px 40px 8px rgba(0, 0, 0, 0.3));
  }

  .lbg-6 {
    top: 75vh;
    left: 60vw;
    width: 400px;
  }

  .lbg-7 {
    top: 95vh;
    left: 32vw;
    width: 300px;
    opacity: 0.5;
    transform: rotate(90deg);
  }

  .lbg-8 {
    top: -220px;
    left: 17vw;
    width: 510px;
  }
}

@keyframes randomMove {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(
      calc(100px - (200px * var(--random-x))),
      calc(100px - (200px * var(--random-y))),
      0
    );
  }
}

.ani-2 {
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ani-2;
  animation-duration: 30s;
  transform-origin: top center;
}

@keyframes ani-2 {
  0% {
    transform: translate3d(-6px, -46px, 0px);
  }
  30% {
    transform: translate3d(5px, -3px, 6px);
  }
  60% {
    transform: translate3d(-15px, 15px, -15px);
  }
  100% {
    transform: translate3d(-6px, -46px, 0px);
  }
}

.ani-3 {
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ani-3;
  animation-duration: 30s;
  transform-origin: top center;
}
@keyframes ani-3 {
  0% {
    transform: translate3d(6px, 46px, 0px);
  }
  30% {
    transform: translate3d(-5px, 3px, -6px);
  }
  60% {
    transform: translate3d(15px, -15px, 15px);
  }
  100% {
    transform: translate3d(6px, 46px, 0px);
  }
}
.ani-4 {
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ani-4;
  animation-duration: 30s;
  transform-origin: top center;
}
@keyframes ani-4 {
  0% {
    transform: translate3d(6px, 26px, 0px);
  }
  30% {
    transform: translate3d(-5px, 3px, -6px);
  }
  60% {
    transform: translate3d(15px, 10px, 0px);
  }
  100% {
    transform: translate3d(6px, 26px, 0px);
  }
}
.error {
  padding: 20px 0;
  font-weight: 500;
  color: #d92a36;
}
video {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4;
  opacity: 0.3;
  transform: scale(1.4);
  transform-origin: left center;
  mix-blend-mode: screen;
}
.bgtint {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: var(--primarycolor);
  background-size: 5px 5px;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.03) 1px, rgba(0, 0, 0, 0) 1px);
  z-index: 2;
}
.bgtint-grad {
  background-color: transparent;
  background: transparent;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0) 43%, rgba(0, 0, 0, 1) 100%);
  z-index: 3;
  opacity: 0.3;
}
</style>
